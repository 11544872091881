type WhereExpressionType = 'above'
    | 'aboveOrEqual'    | 'anyOf'                | 'anyOfIgnoreCase'
    | 'below'           | 'belowOrEqual'         | 'between'
    | 'equals'          | 'equalsIgnoreCase'     | 'inAnyRange'
    | 'noneOf'          | 'notEqual'             | 'startsWith'
    | 'startsWithAnyOf' | 'startsWithIgnoreCase' | 'startsWithAnyOfIgnoreCase';

type SupportedIndexKeyTypes = number | Date | string | Array<number> | Array<Date> | Array<string>;

interface IWhereExpression<T extends WhereExpressionType> {
    type: T;
    keyPath: string | Array<string>;
}

interface IAboveWhereExpression extends IWhereExpression<'above'> {
    lowerBound: SupportedIndexKeyTypes;
}

interface IAboveOrEqualWhereExpression extends IWhereExpression<'aboveOrEqual'> {
    lowerBound: SupportedIndexKeyTypes;
}

interface IAnyOfWhereExpression extends IWhereExpression<'anyOf'> {
    keys: Array<SupportedIndexKeyTypes>;
}

interface IAnyOfIgnoreCaseExpression extends IWhereExpression<'anyOfIgnoreCase'> {
    keys: Array<string>;
}

interface IBelowExpression extends IWhereExpression<'below'> {
    upperBound: SupportedIndexKeyTypes;
}

interface IBelowOrEqualExpression extends IWhereExpression<'belowOrEqual'> {
    upperBound: SupportedIndexKeyTypes;
}

interface IBetweenWhereExpression extends IWhereExpression<'between'> {
    lowerBound: SupportedIndexKeyTypes;
    upperBound: SupportedIndexKeyTypes;
    includeLower: boolean;
    includeUpper: boolean;
}

interface IEqualsWhereExpression extends IWhereExpression<'equals'> {
    key: SupportedIndexKeyTypes;
}

interface IEqualsIgnoreCaseWhereExpression extends IWhereExpression<'equalsIgnoreCase'> {
    key: string;
}

interface IInAnyRangeWhereExpression extends IWhereExpression<'inAnyRange'> {
    ranges: Array<{
        0: any;
        1: any;
    }>;
    includeLowers: boolean;
    includeUppers: boolean;
}

interface INoneOfWhereExpression extends IWhereExpression<'noneOf'> {
    keys: Array<SupportedIndexKeyTypes>;
}

interface INotEqualWhereExpression extends IWhereExpression<'notEqual'> {
    key: SupportedIndexKeyTypes;
}

interface IStartsWithWhereExpression extends IWhereExpression<'startsWith'> {
    prefix: string;
}

interface IStartsWithAnyOfWhereExpression extends IWhereExpression<'startsWithAnyOf'> {
    prefixes: Array<string>;
}

interface IStartsWithIgnoreCaseWhereExpression extends IWhereExpression<'startsWithIgnoreCase'> {
    prefix: string;
}

interface IStartsWithAnyOfIgnoreCaseWhereExpression extends IWhereExpression<'startsWithAnyOfIgnoreCase'> {
    prefixes: Array<string>;
}

export type WhereExpression = IAboveWhereExpression
    | IAboveOrEqualWhereExpression    | IAnyOfWhereExpression                | IAnyOfIgnoreCaseExpression
    | IBelowExpression                | IBelowOrEqualExpression              | IBetweenWhereExpression
    | IEqualsWhereExpression          | IEqualsIgnoreCaseWhereExpression     | IInAnyRangeWhereExpression
    | INoneOfWhereExpression          | INotEqualWhereExpression             | IStartsWithWhereExpression
    | IStartsWithAnyOfWhereExpression | IStartsWithIgnoreCaseWhereExpression | IStartsWithAnyOfIgnoreCaseWhereExpression;

export default WhereExpression;
